html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #444444;
  color: white;
}

body > div.panolens-container {
  background-color: #444444 !important;
}

a:link,
a:visited {
  color: #444444;
}

canvas { 
  height: 100vh !important; 
  width: 100vw !important; 
  display: block;
}

.credit {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 20px 0;
  color: #fff;
}

#container {
  width: 100%;
  height: 100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

.bm-burger-button:visited {
  display: none;
}

.divider {
  height: 3px;
  background: white;
  width: 100%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #444444;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #444444;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

.bm-item li:hover {
  text-decoration: underline;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

h2 {
  color: white;
}

/* .fullpage {
  height: 100vh;
} */

.intro-title,
.about-title {
  font-size: 30px;
  margin-top: 70px;
  text-align: center;
}

h1 {
  font-style: italic;
}

.intro-title h2,
.about-title h2 {
  font-size: 24px;
}

.intro-text p {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.intro-text,
.about-blurb {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.intro-text p a {
  color: white;
  text-decoration: underline;
}

.about-blurb p {
  font-size: 20px;
  font-weight: bold;
}

.cyoa-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.image-details-flex {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.image-detail {
  font-size: 20px;
  /* font-weight: bold; */
  width: 33%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 50px;
}

.image-detail:last-child {
  margin-bottom: 30px;
}

.image-detail .sphere-title {
  font-weight: bold;
  margin-bottom: 15px;
}

.image-detail .ital {
  font-style: italic;
}

.image-detail p {
  width: 60%;
  margin: 0;
  margin-bottom: 15px;
  font-weight: bold;
}

.intro-text p,
.about-blurb p {
  width: 66%;
}

.hide {
  display: none;
}

button {
  padding: 10px;
  border: 2px solid white;
  background: transparent;
  color: white;
  margin: 0 20px;
  transition: all 0.4s;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
}

button:hover {
  background: white;
  color: #444444;
}

a,
a:visited {
  color: #444444;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  transform: none !important;
  transition: none !important;
}

li {
  font-size: 30px;
  margin-bottom: 20px;
}

a:hover {
  color: #373a47;
}

.about-artist {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.about-blurb-flex p {
  text-align: left;
}

.socials {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.social-icon {
  pointer-events: none !important;
}

.socials a {
  margin: 5px;
}

.flex-image img {
  width: 200px;
}

.mailing-list {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

form {
  display: flex;
  justify-content: center;
}

input {
  background: #444444;
  color: white;
  border: 2px solid white;
  padding: 10px;
  font-size: 20px;
}

.mailing-list button {
  font-size: 20px;
}

.mailing-list p {
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

#typed {
  color: #fff;
  text-shadow: 0px 0px 2px #000;
  font-size: 24pt;
}

#dialog {
  position: absolute;
  top: 50%;
  width: 66%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  pointer-events: none;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

#progress {
  width: 0;
  height: 5px;
  position: fixed;
  top: 0;
  background: #fff;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

#progress.finish {
  opacity: 0;
}

video {
  display: none;
}

#progress {
  width: 0;
  height: 5px;
  position: fixed;
  top: 0;
  background: #fff;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

#progress.finish {
  opacity: 0;
}


@keyframes fadeIn{
  0% {
    opacity:0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
  pointer-events: none;
}

.artist, .land {
  margin-top: 2%;
}

.artist h3, .land h3, .image-details h3 {
  font-size: 28px;
}

.image-details h3 {
  margin-bottom: 40px;
}

.about-title h2 {
  font-size: 40px;
  font-style: italic;
}

.about-blurb span {
  font-style: italic;
}


.about-blurb a {
  color: white !important;
  text-decoration: underline;
}

.artist-blurb, .land-blurb, .about-blurb {
  margin-bottom: 30px;
}

.artist-blurb, .artist-blurb p, .land-blurb, .land-blurb p, .about-blurb p {
  margin-top: 0;
}

p.location {
  margin-top: 2%;
}

.info, .thanks {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

.info img {
  max-width: 500px;
  margin-bottom: 1%;
}

.info a {
  font-weight: bold;
}

.infoText {
  margin: 0;
  padding: 0;
  font-weight: bold;
  
}

.infoText a, .flex-contact a {
  color: white !important;
  text-decoration: underline;
}

.infoText a:hover, .flex-contact a:hover, .intro-text a:hover, .about-blurb a:hover {
  opacity: 0.7;
}

input {
  background: white;
  color: #444444;
}

.one {
  margin-bottom: 10px;
}

.thanks {
  font-weight: bold;
  margin-top: 30px;
}

.thanks a {
  color: white;
}

.thanks a:hover {
  text-decoration: underline;
}

.flex-contact img {
  width: 100px;
}

.thanks p {
  margin: 0 auto;
  margin-bottom: 2%;
}

.img-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 50px 0; */
  margin-top: 20px;
  margin-bottom: 30px;
}

.flex-contact img.oac {
  width: 200px;
}

.flex-contact img {
  margin: 0 5px;
}

.image-details {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.end {
  display: none;
}

.end a {
  color: white;
  text-decoration: underline;
}

.show {
  position: absolute;
  z-index: 100;
  display: flex !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #444444;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

.no-touch {
  pointer-events: none;
}

.socials a {
  transition: all 0.3s;
}

.socials a:hover {
  opacity: 0.7;
}

.end h2 {
  margin-top: 70px;
  width: 80%;
}

.end p {
  width: 80%;
}

a:link, a:visited {
  color: #444444;
}

.artist-flex-big {
  display: flex;
  flex-direction: row-reverse;
}

.info {
  margin-top: 30px;
}

.about-blurb p {
  font-size: 26px;
  text-align: center;
  width: 75%;
}

.mailing-list h3 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}

.mailing-list {
  margin-bottom: 30px;
}

.flex-one {
  width: 55%;
}

.thanks {
  margin: 30px 0;
}

.end a {
  color: white;
  transition: all 0.3s;
}

.end a:hover {
  opacity: 0.7;
}

.cyoa-buttons {
  margin-top: 30px;
}

@media only screen and (max-width: 1200px) {
  .intro-title h1, .about-title h2 {
    font-size: 30px;
    width: 65%;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .cyoa-buttons {
    margin-top: 0;
  }

  .intro-title {
    margin-top: 70px;
  }

  .intro-title h2, .end p {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .intro-text p {
    font-size: 16px;
    text-align: center;
  }

  .artist-flex-big {
    flex-direction: column;
    
  }

  .cyoa-buttons button {
    margin-top: 30px;
    font-size: 20px;
  }

  .flex-one {
    width: 100%;
  }

  .info img {
    max-width: 400px;
  }

  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    right: 30px;
    top: 30px;
  }

  .image-detail {
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {

  .image-detail {
    width: 100%;
  }
  
  .end h2 {
    margin-top: 70px;
    font-size: 30px;
    width: 80%;
  }

  .artist-blurb {
    margin-bottom: 0px;
  }

  .about-blurb p {
    font-size: 18px;
  }

  .about-title h2 {
    width: 75%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}


@media only screen and (max-width: 600px) {
  .info img {
    max-width: 280px;
  }

  input {
    max-width: 200px;
    margin-left: 20px;
  }
}



